<template>
<div>
  <confirmation ref="Confirmation"></confirmation>
  <datatable-heading
    :title="$t('menu.email-templates')"
    :changePageSize="changePageSize"
    :searchChange="_.debounce(searchChange, 500)"
    :from="from"
    :to="to"
    :total="total"
    :perPage="perPage"
  >
  </datatable-heading>

  <b-overlay :show="gridLoading" variant="transparent" blur="5px" opacity="1">
    <b-row>
      <b-colxx xxs="12">
        <b-card>
          <vuetable
            ref="vuetable"
            :api-url="apiBase"
            :query-params="makeQueryParams"
            :per-page="perPage"
            :reactive-api-url="false"
            :fields="fields"
            :css="css.table"
            pagination-path
            :row-class="onRowClass"
            @vuetable:pagination-data="onPaginationData"
            @vuetable:row-clicked="rowClicked"
            @vuetable:cell-rightclicked="rightClicked"
            @vuetable:load-error="handleLoadError"
            @vuetable:cell-clicked="cellclicked"
            @vuetable:loading="onVuetableLoading"
            @vuetable:loaded="onVuetableLoaded"
          >
            <template slot="activeEmailTemplate" slot-scope="props">
              <div style="display:flex;justify-content:center;align-items;center;">
                <ToggleSwitch
                  v-on:input.self="flipActive(props.rowData)"
                  v-model="props.rowData.active"
                  :emitOnMount="false"
                  theme="custom"
                  class="vue-switcher-small"
                  color="primary"
                  :id="'toggle-' + props.rowData.name"
                  v-b-tooltip.hover.left :title="`Deactivate ${props.rowData.name}`"
                />
              </div>
            </template>
          </vuetable>
        </b-card>
        <vuetable-pagination-bootstrap
          class="mt-4"
          ref="pagination"
          @vuetable-pagination:change-page="onChangePage"
        />
      </b-colxx>
    </b-row>
  </b-overlay>
  <v-contextmenu ref="contextmenu">
       <v-contextmenu-item @click="onContextMenuAction('show_or_edit')">
        <i class="simple-icon-docs" />
        <span>Show / Edit</span>
      </v-contextmenu-item>
    </v-contextmenu>
</div>
</template>
<script>
import manageCss from '../../../../assets/css/employees/manage-employees.css'
import confirmation from '../../../../components/Common/Confirmation.vue'
import Vuetable from 'vuetable-2/src/components/Vuetable'
// import Moment from 'moment/moment.js'
import VuetablePaginationBootstrap from '../../../../components/Common/VuetablePaginationBootstrap'
import Switches from 'vue-switches'
import { BeaconapiUrl } from '../../../../constants/config'

import moment from 'moment/moment.js'
import DatatableHeading from '../../../../containers/datatable/DatatableHeading'
import CssTableConfig from '../../../../constants/VuetableBootstrapconfig'
import EmailTemplateMixin from '../../../../mixins/EmailTemplateMixin.vue'
import { mapMutations, mapActions } from 'vuex'

export default {
  props: ['title'],
  components: {
    vuetable: Vuetable,
    'vuetable-pagination-bootstrap': VuetablePaginationBootstrap,
    'datatable-heading': DatatableHeading,
    Confirmation: confirmation,
    ToggleSwitch: Switches
  },
  mixins: [EmailTemplateMixin],
  data () {
    return {
      tooltipDisabled: false,
      isLoad: false,
      apiBase: `${BeaconapiUrl}/api/email-template/table`,
      sort: '',
      page: 1,
      perPage: 50,
      search: '',
      from: 0,
      to: 0,
      total: 0,
      lastPage: 0,
      items: [],
      selectedItems: [],
      idfield: 'emailTemplateID',
      css: CssTableConfig,
      gridLoading: false,
      fields: [
        {
          name: 'name',
          sortField: 'name',
          title: 'Name',
          titleClass: '',
          dataClass: 'text-muted',
          width: '10%'
        },
        {
          name: 'subject',
          sortField: 'subject',
          title: 'Subject',
          titleClass: '',
          dataClass: 'text-muted',
          width: '10%'
        },
        {
          name: 'createdBy',
          sortField: 'createdBy',
          title: 'Created By',
          titleClass: '',
          dataClass: 'text-muted',
          width: '9%'
        },
        {
          name: 'dateCreated',
          sortField: 'dateCreated',
          title: 'Created Date',
          titleClass: '',
          dataClass: 'text-muted',
          width: '10%',
          callback: this.formatDate
        },
        {
          name: 'lastModifiedBy',
          sortField: 'lastModifiedBy',
          title: 'Last Modified By',
          titleClass: '',
          dataClass: 'text-muted',
          width: '9%'
        },
        {
          name: 'dateLastModified',
          sortField: 'dateLastModified',
          title: 'Last Modified Date',
          titleClass: '',
          dataClass: 'text-muted',
          width: '10%',
          callback: this.formatDate
        },
        {
          name: '__slot:activeEmailTemplate',
          sortField: 'active',
          title: 'Active Template',
          titleClass: 'center aligned text-center',
          dataClass: 'center aligned',
          width: '10%'
        }
      ]
    }
  },
  methods: {
    ...mapMutations(['setDangerMessage', 'setAlertMessage', 'setInfoMessage', 'appendInfoMessage']),

    // Vue Table plumbing methods (standardized)

    makeQueryParams (sortOrder, currentPage, perPage) {
      this.selectedItems = []
      return sortOrder[0]
        ? {
          sort: sortOrder[0]
            ? sortOrder[0].field + '|' + sortOrder[0].direction
            : '',
          page: currentPage,
          per_page: this.perPage,
          search: this.search
        }
        : {
          page: currentPage,
          per_page: this.perPage,
          search: this.search
        }
    },
    onVuetableLoading () {
      this.gridLoading = true
    },
    onVuetableLoaded () {
      this.gridLoading = false
    },
    onPaginationData (paginationData) {
      this.from = paginationData.from
      this.to = paginationData.to
      this.total = paginationData.total
      this.lastPage = paginationData.last_page
      this.items = paginationData.data
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    changePageSize (perPage) {
      this.perPage = perPage
      this.refreshVueTable()
    },
    searchChange (val) {
      this.search = val
      this.refreshVueTable()
    },
    handleLoadError (response) {
      this.setDangerMessage(response)
    },
    onRowClass (dataItem, index) {
      var outputclass = ''
      if (this.selectedItems.includes(dataItem[this.$data.idfield])) {
        outputclass = 'selected'
      }
      return outputclass
    },
    cellclicked (dataitem, field, event) {
    },
    rightClicked (dataItem, field, event) {
      event.preventDefault()
      this.rightClickedItem = dataItem
      const itemId = dataItem[this.$data.idfield]

      if (!this.$refs.vuetable.selectedTo.includes(itemId)) {
        this.$refs.vuetable.selectedTo = [itemId]
      }
      this.$refs.contextmenu.show({ top: event.pageY, left: event.pageX })
      this.selectedItems = this.$refs.vuetable.selectedTo
    },
    getIndex (value, arr, prop) {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i][prop] === value) {
          return i
        }
      }
      return -1
    },
    refreshVueTable () {
      this.$refs.vuetable.refresh()
    },
    rowClicked (dataItem, event) {
      const itemId = dataItem[this.$data.idfield]

      if (this.$refs.vuetable.selectedTo.includes(itemId)) {
        this.$refs.vuetable.selectedTo = this.$refs.vuetable.selectedTo.filter(x => x !== itemId)
      } else {
        this.$refs.vuetable.selectedTo.push(itemId)
      }
      this.selectedItems = this.$refs.vuetable.selectedTo
    },

    // End of Standard Vue Table methods

    // Component-specific Vue Table methods

    onContextMenuAction (action) {
      var rightClickedItem = this.rightClickedItem
      switch (action) {
        case 'show_or_edit':
        {
          this.$router.push({ name: 'editEmailTemplate', params: { emailTemplateID: this.selectedItems[0] } })
          break
        }
        default:
          break
      }
    },

    // End of Component-specific Vue Table methods

    // Component-specific Methods

    formatDate (value) {
      if (value === null) {
        return ''
      } else {
        return moment(value, 'YYYY-MM-DD').format('MM/DD/YYYY')
      }
    },
    flipActive (template) {
      this.$refs.Confirmation.show({
        title: 'Deactivate Template',
        message: 'Are you sure you want to deactivate' + ` ${template.name}?  You will need to navigate to another page to reactivate them.`,
        okButton: 'Yes'
      }).then(async (result) => {
        if (result) {
          this.setInfoMessage('')
          var templateToDeactivate = {
            id: template.emailTemplateID,
            active: template.active
          }
          await this.deactivateTemplate([templateToDeactivate])
            .then(response => {
              this.refreshVueTable()
            })
            .catch(err => {
              if (err.response) {
                // client received an error response (5xx, 4xx)
                this.setDangerMessage(err.message)
              } else if (err.request) {
                // client never received a response, or request never left
                this.setDangerMessage(err.message)
              } else {
                // anything else
                this.setDangerMessage(err.message)
              }
            })
        } else {
          this.refreshVueTable()
        }
      })
    }

    // End of Component-specific methods
  }
}
</script>
