<script>
import axios from 'axios'
import { BeaconapiUrl } from '../constants/config'
import { mapMutations } from 'vuex'

export default {
  data () {
    return {
      emailTemplates: [],
      emailTemplate: {},
      statusCode: null
    }
  },

  methods: {
    ...mapMutations(['setDangerMessage', 'setAlertMessage', 'setInfoMessage', 'appendInfoMessage', 'appendDangerMessage']),
    async getAllEmailTemplates (search, page, perPage) {
      await axios
        .get(`${BeaconapiUrl}/api/email-template/table?search=${search}&page=${page}&per_page=${perPage}`)
        .then((result) => {
          this.emailTemplates = result.data
          this.statusCode = result.status
        })
        .catch((error) => {
          this.setDangerMessage(error)
        })
    },
    async getEmailTemplateById (emailTemplateId) {
      return await axios
        .get(`${BeaconapiUrl}/api/email-template/${emailTemplateId}`)
        .then((result) => { this.emailTemplate = result.data })
        .catch((error) => {
          this.setDangerMessage(error)
        })
    },
    async updateEmailTemplate (emailTemplate) {
      // update the last modified by to current user
      emailTemplate.lastModifiedByID = localStorage.getItem('employeeID')
      await axios
        .put(`${BeaconapiUrl}/api/email-template`, emailTemplate)
        .then((result) => {
          this.statusCode = result.status
          this.setInfoMessage(`Email Template: ${result.data.name} Successfully Updated!`)
        })
        .catch((error) => {
          this.setDangerMessage(error)
        })
    },
    // Active/Deactive template
    async deactivateTemplate (emailTemplate) {
      await axios
        .put(`${BeaconapiUrl}/api/email-template/activate`, emailTemplate)
        .then((result) => {
          this.statusCode = result.status
          this.setInfoMessage(result.data)
        })
        .catch((error) => {
          this.setDangerMessage(error)
        })
    },
    // All email templates that can be modified by the user before sending should have a function defined here
    // The function simply requests the necessary field values as parameters and then creates an array of fieldname/fieldvalue pairs.
    createTemplateBodyFields_LeadQuote (quoteAmt) {
      var templateBodyFields = [{
        fieldName: '{{{QuoteAmount}}}',
        fieldValue: quoteAmt
      }]
      return templateBodyFields
    }
  }
}
</script>
